AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

$(window).ready(function () {
  setTimeout(function () {
    $(".loader-container").addClass("active");
  }, 1500);
});

// function openTab(evt, tabName) {
//     console.log("1")
//     var i, tabcontent, tablinks;
//     tabcontent = document.getElementsByClassName("tabcontent");
//     console.log(tabcontent)
//     for (i = 0; i < tabcontent.length; i++) {
//         tabcontent[i].style.display = "none";
//     }
//     tablinks = document.getElementsByClassName("tablinks");
//     console.log(tablinks)
//     for (i = 0; i < tablinks.length; i++) {
//         tablinks[i].className = tablinks[i].className.replace(" active", "");
//     }
//     $('#' + tabName).fadeIn(1500)
//     // document.getElementById(tabName).style.display = "block";
//     evt.currentTarget.className += " active";
// }

// salesforce sub page tab

function openSalesTab(evt, salestabName) {
  console.log("1");
  var i, salestabcontent, salsetablinks;
  salestabcontent = document.getElementsByClassName("salestabcontent");
  console.log(salestabcontent);
  for (i = 0; i < salestabcontent.length; i++) {
    salestabcontent[i].style.display = "none";
  }
  salsetablinks = document.getElementsByClassName("salsetablinks");
  console.log(salsetablinks);
  for (i = 0; i < salsetablinks.length; i++) {
    salsetablinks[i].className = salsetablinks[i].className.replace(
      " active",
      ""
    );
  }
  $("#" + salestabName).fadeIn(1500);
  // document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
}

var myFullpage = new fullpage("#fullpage", {
  verticalCentered: false,
  css3: false,
});

if ($("#web_min_gal").length > 0) {
  let test = $("#web_min_gal");
  test.owlCarousel({
    loop: true,
    autoplay: true,
    margin: 80,
    nav: false,
    //autoWidth:true,
    rtl: false,
    slideTransition: "linear",
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 3,
      },
    },
  });

  $("#web_min_gal .owl-nav button.owl-next").mouseenter(function () {
    test.trigger("destroy.owl.carousel");
    console.log("next");
    test.owlCarousel({
      loop: true,
      autoplay: true,
      margin: 80,
      nav: false,
      rtl: true,
      slideTransition: "linear",
      autoplayTimeout: 2000,
      autoplaySpeed: 2000,
      autoplayHoverPause: false,
      responsive: {
        0: {
          items: 3,
        },
      },
    });
    console.log("next");
    //alert("sfsdfs");
    // test.owlCarousel({
    //     rtl:true,
    //     autoplayHoverPause: false
    // })
    //var carousel = test.data('owl.carousel');
    //console.log('carousel', carousel.options.rtl)
    //   //carousel.settings.stagePadding = StgPad;
    // carousel.options.rtl = true;
    //test.reinit({rtl: true})
    //carousel.trigger('refreshed.owl.carousel')
    //         test.trigger('change.owl.carousel', {
    //   rtl: true
    // });
  });

  $("#web_min_gal .owl-nav button.owl-prev").mouseenter(function () {
    test.trigger("destroy.owl.carousel");
    console.log("left");
    test.owlCarousel({
      loop: true,
      autoplay: true,
      margin: 80,
      nav: false,
      rtl: true,
      slideTransition: "linear",
      autoplayTimeout: 2000,
      autoplaySpeed: 2000,
      autoplayHoverPause: false,
      responsive: {
        0: {
          items: 3,
        },
      },
    });
    //     console.log('left')
    //     var carousel = test.data('owl.carousel');
    //       //carousel.settings.stagePadding = StgPad;
    //       carousel.options.rtl = false;
    //test.trigger('refresh.owl.carousel')
  });
}

if ($("#tech_sld").length > 0) {
  $("#tech_sld").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  });
}

if ($("#hrv_slider").length > 0) {
  $("#hrv_slider").owlCarousel({
    loop: true,
    autoplay: true,
    arrows: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
      0: {
        stagePadding: 50,
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  });
}

if ($("#wdw-slide").length > 0) {
  $("#wdw-slide").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 120,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });
}

$(document).ready(function () {
  $("#open-item-mobile").click(function () {
    $(".mobile-menu").addClass("active");
  });
  $("#close-item-mobile").click(function () {
    $(".mobile-menu").removeClass("active");
  });
});

new WOW().init();

if ($("#cp_sld_img").length > 0) {
  $("#cp_sld_img").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 30,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        margin: 10,
      },
      600: {
        items: 2,
        nav: true,
        margin: 10,
      },
      1000: {
        items: 4,
      },
    },
  });
}


if ($('#case_sld_img').length > 0) {
  $('#case_sld_img').owlCarousel({
    loop: true,
    autoplay: true,
    margin: 30,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2.5,
        nav: true,
        margin: 10
      },
      600: {
        items: 2.5,
        nav: true,
        margin: 10
      },
      800: {
        items: 3.5,
        nav: true,
        margin: 10
      },
      1000: {
        items: 4.5,
        nav: true
      }
    }
  })
}

if ($("#case-mob-wire").length > 0) {
  $("#case-mob-wire").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 30,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
        margin: 10,
      },
      600: {
        items: 3,
        nav: false,
        margin: 10,
      },
      1000: {
        items: 4,
      },
    },
  });
}

// setTimeout(function () {
//     typemth();
// }, 5000);

// $(".serv_tabs_ls li a").click(function (event) {
//     event.preventDefault();
//     var hashid = $(this).attr('href');
//     $(".serv_tabs_ls li a").removeClass("active");
//     $(this).addClass("active");
//     $(".serv_tb").hide().removeClass('aos-animate');
//     $(hashid + ".serv_tb").show();
//     setTimeout(function () {
//         $(".serv_tb").addClass('aos-animate');
//     }, 5);
// });
// $('#clin_ls').slick({
//     dots: false,
//     variableWidth: true,
//     arrows: false,
//     speed: 3000,
//     autoplay: true,
//     autoplaySpeed: 0,
//     cssEase: 'linear',
//     slidesToShow: 1,
//     slidesToScroll: 1,
// });
// $('#clin_ls_award').slick({
//     dots: false,
//     variableWidth: true,
//     arrows: false,
//     speed: 3000,
//     autoplay: true,
//     autoplaySpeed: 0,
//     cssEase: 'linear',
//     slidesToShow: 1,
//     slidesToScroll: 1,
// });
// $('#hm_banner_logo').slick({
//     dots: false,
//     variableWidth: true,
//     arrows: false,
//     speed: 1500,
//     autoplay: true,
//     autoplaySpeed: 0,
//     cssEase: 'linear',
//     slidesToShow: 2,
//     pauseOnHover:true,
//     slidesToScroll: 1,
//     pauseOnFocus: false,
// });
// $('#productsSl').slick({
//     dots: false,
//     variableWidth: true,
//     arrows: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     centerMode: true,
//     variableWidth: true,
//     infinite: true,
//     draggable: false,
//     prevArrow: $('.pr_prev'),
//     nextArrow: $('.pr_next'),
//     responsive: [{
//         breakpoint: 676,
//         settings: {
//             arrows: false,
//             dots: true
//         }
//     }]
// });

$(function () {
  AOS.init();

  function typemth() {
    Typed.new(".type", {
      strings: ["Digital Experience", "Mobile Experience", "Web Experience"],
      stringsElement: null,
      typeSpeed: 100,
      startDelay: 600,
      backSpeed: 20,
      backDelay: 4000,
      loop: true,
      showCursor: false,
      cursorChar: "|",
      attr: null,
      contentType: "html",
    });
  }

  setTimeout(function () {
    typemth();
  }, 5000);

  $(".serv_tabs_ls li a").click(function (event) {
    event.preventDefault();
    var hashid = $(this).attr("href");
    $(".serv_tabs_ls li a").removeClass("active");
    $(this).addClass("active");
    $(".serv_tb").hide().removeClass("aos-animate");
    $(hashid + ".serv_tb").show();
    setTimeout(function () {
      $(".serv_tb").addClass("aos-animate");
    }, 5);
  });
  $("#clin_ls").slick({
    dots: false,
    variableWidth: true,
    arrows: false,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  $("#clin_ls_award").slick({
    dots: false,
    variableWidth: true,
    arrows: false,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 676,
        settings: {
          pauseOnHover: false,
        },
      },
    ],
  });

  $("#hm_banner_logo").slick({
    dots: false,
    variableWidth: true,
    arrows: false,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 2,
    pauseOnHover: true,
    slidesToScroll: 1,
    pauseOnFocus: false,
  });
  $("#sales_banner_logo").slick({
    dots: false,
    variableWidth: true,
    arrows: false,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 2,
    pauseOnHover: true,
    slidesToScroll: 1,
    pauseOnFocus: false,
  });

  $("#trainee-slider").slick({
    dots: false,
    variableWidth: true,
    arrows: false,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 2,
    pauseOnHover: true,
    slidesToScroll: 1,
    pauseOnFocus: false,
  });
  $("#productsSl").slick({
    dots: false,
    variableWidth: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    infinite: true,
    draggable: false,
    prevArrow: $(".pr_prev"),
    nextArrow: $(".pr_next"),
    responsive: [
      {
        breakpoint: 676,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  });

  $("body").on("click touchstart", function () {
    const videoElement = document.getElementById("bannerVideo");
    if (videoElement) {
      if (videoElement.playing) {
        // video is already playing so do nothing
      } else {
        videoElement.play();
      }
    }
  });

  $("#themes .column").hover(
    function () {
      $(this).addClass("hover");
      $("#themes .column").not(this).addClass("not_hover");
    },
    function (e) {
      $("#themes .column").removeClass("hover");
      $("#themes .column").removeClass("not_hover");
      e.stopPropagation();
    }
  );

  $(".box").hover(
    function () {
      $(this).addClass("hover");
    },
    function () {
      $(this).removeClass("hover");
    }
  );

  if ($(window).width() <= 768) {
    $(".close_column").on("click", function () {
      $("#themes .column").removeClass("hover");
      $("#themes .column").removeClass("not_hover");
      $("#themes .column .box").removeClass("hover");
    });
  }

  var winht = $(window).height(),
    winhwt = $(window).width();
  var controller = new ScrollMagic.Controller({
    container: window,
  });
  let scene;

  scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    offset: winht / 2 + 2,
    duration: winht / 2 + winht / 5,
  })
    .setTween(".mouse_ani .ln", 0.5, {
      transform: "scaleY(1)",
    })
    .addTo(controller);
  //.addIndicators();

  var blgslwt = $("#blog_sld").innerWidth();
  var blgslitwt = $("#blog_sld .item").innerWidth();
  var blgslitlnt = $("#blog_sld .item").length;
  var blgslitlntwt = blgslitwt * blgslitlnt;
  var sldval = blgslitlntwt - blgslwt;

  if ($("#trigger3").length > 0) {
    console.log("trigger3");

    scene = new ScrollMagic.Scene({
      triggerElement: "#trigger3",
      offset: winht / 1.5,
      duration: sldval,
    })
      .setPin(".blog_sec_inner")
      .addTo(controller);

    //.addIndicators();

    scene = new ScrollMagic.Scene({
      triggerElement: "#trigger3",
      offset: winht / 1.5,
      duration: sldval,
    })
      .setTween("#blog_sld", 2, {
        x: -sldval,
      })
      .addTo(controller);
    //.addIndicators();
  }
  if ($("#trigger4").length > 0) {
    scene = new ScrollMagic.Scene({
      triggerElement: "#trigger4",
      offset: winht / 5,
      duration: winht / 2,
    })
      .setPin(".career_section_inner")
      .addTo(controller);
    //.addIndicators();

    scene = new ScrollMagic.Scene({
      triggerElement: "#trigger4",
      offset: winht / 2,
      duration: winht / 2,
    })
      .setTween(".ofm", 0.2, {
        scale: 1,
      })
      .addTo(controller);
    //.addIndicators();

    scene = new ScrollMagic.Scene({
      triggerElement: "#trigger4",
      offset: winht - winht / 4,
      duration: winht / 2,
    })
      .setTween(".career_section_inner", 0.2, {
        borderColor: "#c29109",
      })
      .addTo(controller);
    //.addIndicators();
  }

  var appendthis = "<div class='modal-overlay js-modal-close'></div>";
  $("a[data-modal-id]").click(function (e) {
    e.preventDefault();
    $("body").append(appendthis);
    $(".modal-overlay").fadeTo(500, 0.7);
    var modalBox = $(this).attr("data-modal-id");
    $("#" + modalBox).fadeIn($(this).data());
    $("body").addClass("popup_open");
  });
  $(".js-modal-close, .modal-box").on("click", function () {
    $(".modal-box, .modal-overlay").fadeOut();
    $(".modal-overlay").remove();
    $("body").removeClass("popup_open");
  });
  $(".getin_touch_pop_ins").on("click", function (event) {
    event.stopImmediatePropagation();
  });

  function blogsld() {
    $("#blog_sld").slick({
      dots: true,
      variableWidth: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
    $(".serv_tabs_ls").slick({
      dots: true,
      variableWidth: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
    });

    $(".mb_drop").click(function (e) {
      e.preventDefault();
      let $this = $(this);
      if ($this.next().hasClass("show")) {
        $this.next().removeClass("show");
        $this.removeClass("active");
        $this.next().slideUp(350);
      } else {
        $this.parent().parent().find(".foot_nav").removeClass("show");
        $this.parent().parent().find(".foot_nav").slideUp(350);
        $this.parent().parent().find(".mb_drop").removeClass("active");
        $this.next().toggleClass("show");
        $this.toggleClass("active");
        $this.next().slideToggle(350);
      }
    });
  }

  // ///////////////////////////////

  var windowwidth;
  if ((windowwidth = $(window).width()) <= 1040) {
    var ncards = $(".cards li").length;
    (rotate = 0), (number = 45 * -ncards), (slide = 0);
  }
  if ((windowwidth = $(window).width()) >= 1025) {
    ncards = $(".cards li").length;
    (rotate = 0), (number = 54 * -ncards), (slide = 0);
  }
  var stateManager = (function () {
    var t = null,
      a = function () {
        $("body").width() < 768
          ? ("mobile" !== t && e(), i())
          : ("desktop" !== t && n(), r());
      },
      e = function () {
        t = "mobile";
      },
      n = function () {
        t = "desktop";
      },
      i = function () { },
      r = function () { };
    return {
      init: function () {
        a(), $(window).on("resize", a);
      },
    };
  })();

  function move(t) {
    (slide += t),
      $(".cards").css(
        "transform",
        "translateZ(" +
        -number +
        "px) rotateY(" +
        (360 * slide) / ncards +
        "deg)"
      );
    console.log(slide);
  }
  stateManager.init(),
    $(".cards").css("transform", "translateZ(" + -number + "px)"),
    $(".cards li").each(function (t) {
      $(this).css(
        "transform",
        "rotateY(" + (360 * -t) / ncards + "deg) translateZ(" + number + "px)"
      );
    }),
    $(".slide-left").on("click", function () {
      move(-1), deductCarouselProgress();
    }),
    $(".slide-right").on("click", function () {
      move(1), addCarouselProgress();
    });
  var pbVal = 1,
    prevpbVal = 0,
    nextpbVal = 0;
  // auto slide latest project home page slide_container
  // setInterval(function(){
  //     move(1), addCarouselProgress()
  // },4000)
  function addCarouselProgress() {
    let slideCount = $(
      ".section-container-8 .slide_container .cards li"
    ).length;
    let slideCountIncremented = slideCount + 1;
    let slideCountIncrementedThree = slideCount + 4;
    $(".section-container-8 .text-content.next-active").addClass("move-right");
    $(".section-container-8 .text-content.top-active").addClass("move-right");
    // $(".cards-cta-btn").removeClass("onactive"), (pbVal += 1) > 14 && (pbVal = 1), pbVal < 1 && (pbVal = 14), (prevpbVal = pbVal - 1) >= 15 && (prevpbVal = 1), prevpbVal <= 0 && (prevpbVal = 14), (nextpbVal = pbVal + 1) > 18 && (nextpbVal = 1), nextpbVal <= 0 && (nextpbVal = 14), $(".cards-cta-btn-" + prevpbVal).addClass("onactive"), $(".cards-cta-btn-" + pbVal).addClass("onactive"), $(".cards-cta-btn-" + nextpbVal).addClass("onactive");
    $(".cards-cta-btn").removeClass("onactive"),
      (pbVal += 1) > slideCount && (pbVal = 1),
      pbVal < 1 && (pbVal = slideCount),
      (prevpbVal = pbVal - 1) >= slideCountIncremented && (prevpbVal = 1),
      prevpbVal <= 0 && (prevpbVal = slideCount),
      (nextpbVal = pbVal + 1) > slideCountIncrementedThree && (nextpbVal = 1),
      nextpbVal <= 0 && (nextpbVal = slideCount),
      $(".cards-cta-btn-" + prevpbVal).addClass("onactive"),
      $(".cards-cta-btn-" + pbVal).addClass("onactive"),
      $(".cards-cta-btn-" + nextpbVal).addClass("onactive");
    $(".img-container").removeClass("activeSlide");
    $(".img-container-" + pbVal).addClass("activeSlide");
    var t = $(".img-container-" + pbVal).attr("data-case"),
      a = $(".img-container-" + pbVal).attr("data-sub-title"),
      e = $(".img-container-" + pbVal).attr("data-sub-title-link"),
      n = $(".img-container-" + pbVal).attr("data-description"),
      hdp = $(".img-container-" + pbVal).attr("data-description-hd");
    $(".section-container-8 .text-content.next-active .categoryTitle").html(t),
      $(".section-container-8 .text-content.next-active .navigateLink").attr(
        "href",
        e
      ),
      $(".section-container-8 .text-content.next-active .SubsectionTitle").html(
        a
      ),
      $(
        ".section-container-8 .text-content.next-active .Subsectiondescription"
      ).html(n),
      $(".section-container-8 .text-content.next-active .hide_para").html(hdp);
    setTimeout(function () {
      $(".section-container-8 .text-content.top-active .categoryTitle").html(t),
        $(".section-container-8 .text-content.top-active .navigateLink").attr(
          "href",
          e
        ),
        $(
          ".section-container-8 .text-content.top-active .SubsectionTitle"
        ).html(a),
        $(
          ".section-container-8 .text-content.top-active .Subsectiondescription"
        ).html(n),
        $(".section-container-8 .text-content.top-active .hide_para").html(hdp);
      $(".section-container-8 .text-content.next-active").removeClass(
        "move-right"
      );
      $(".section-container-8 .text-content.top-active").removeClass(
        "move-right"
      );
    }, 700);
  }

  function deductCarouselProgress() {
    let slideCount = $(
      ".section-container-8 .slide_container .cards li"
    ).length;
    let slideCountIncremented = slideCount + 1;
    $(".section-container-8 .text-content.next-active").addClass("move");
    $(".section-container-8 .text-content.top-active").addClass("move");
    // $(".cards-cta-btn").removeClass("onactive"), (pbVal -= 1) > 14 && (pbVal = 1), pbVal < 1 && (pbVal = 14), (prevpbVal = pbVal - 1) >= 15 && (prevpbVal = 1), prevpbVal <= 0 && (prevpbVal = 14), (nextpbVal = pbVal + 1) >= 15 && (nextpbVal = 1), nextpbVal <= 0 && (nextpbVal = 14), $(".cards-cta-btn-" + prevpbVal).addClass("onactive "), $(".cards-cta-btn-" + pbVal).addClass("onactive"), $(".cards-cta-btn-" + nextpbVal).addClass("onactive");
    $(".cards-cta-btn").removeClass("onactive"),
      (pbVal -= 1) > slideCount && (pbVal = 1),
      pbVal < 1 && (pbVal = slideCount),
      (prevpbVal = pbVal - 1) >= slideCountIncremented && (prevpbVal = 1),
      prevpbVal <= 0 && (prevpbVal = slideCount),
      (nextpbVal = pbVal + 1) >= slideCountIncremented && (nextpbVal = 1),
      nextpbVal <= 0 && (nextpbVal = slideCount),
      $(".cards-cta-btn-" + prevpbVal).addClass("onactive "),
      $(".cards-cta-btn-" + pbVal).addClass("onactive"),
      $(".cards-cta-btn-" + nextpbVal).addClass("onactive");
    $(".img-container").removeClass("activeSlide");
    $(".img-container-" + pbVal).addClass("activeSlide");
    var t = $(".img-container-" + pbVal).attr("data-case"),
      a = $(".img-container-" + pbVal).attr("data-sub-title"),
      e = $(".img-container-" + pbVal).attr("data-sub-title-link"),
      n = $(".img-container-" + pbVal).attr("data-description"),
      hdp = $(".img-container-" + pbVal).attr("data-description-hd");
    $(".section-container-8 .text-content.next-active .categoryTitle").html(t),
      $(".section-container-8 .text-content.next-active .navigateLink").attr(
        "href",
        e
      ),
      $(".section-container-8 .text-content.next-active .SubsectionTitle").html(
        a
      ),
      $(
        ".section-container-8 .text-content.next-active .Subsectiondescription"
      ).html(n),
      $(".section-container-8 .text-content.next-active .hide_para").html(hdp);

    setTimeout(function () {
      $(".section-container-8 .text-content.top-active .categoryTitle").html(t),
        $(".section-container-8 .text-content.top-active .navigateLink").attr(
          "href",
          e
        ),
        $(
          ".section-container-8 .text-content.top-active .SubsectionTitle"
        ).html(a),
        $(
          ".section-container-8 .text-content.top-active .Subsectiondescription"
        ).html(n),
        $(".section-container-8 .text-content.top-active .hide_para").html(hdp);
      $(".section-container-8 .text-content.next-active").removeClass("move");
      $(".section-container-8 .text-content.top-active").removeClass("move");
    }, 700);
  }

  /////////////////

  function scrollmdes() {
    width = $(window).width();
    if (width <= 900) {
      if (controller) {
        controller = controller.destroy(true);
        blogsld();
      }
    } else if (width > 899) {
      if (!controller) {
        initScrollMagic();
        $("#blog_sld").slick("unslick");
      }
    }
  }

  $(window).resize(function () {
    scrollmdes();
  });
  $(window).width(function () {
    scrollmdes();
  });

  AOS.refreshHard();
});
$(".block_slider").slick({
  centerMode: true,
  centerPadding: "2%",
  slidesToShow: 3,
});

// $(".hm_logo_slider").slick({
//     centerMode: true,
//     centerPadding: '2%',
//     slidesToShow: 5,
//     autoplay: true,
//     arrows: false,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     speed: 300,

//     responsive: [
//         {
//             breakpoint: 520,
//             settings: {
//                 arrows: false,
//                 centerMode: true,
//                 centerPadding: '30px',
//                 slidesToShow: 3,
//                 centerPadding: '4%',
//                 autoplaySpeed: 4000,
//                 speed: 400,
//             }

//         }
//     ]

// })

// JS End

//- sub_service_script--start

/**
 * to change slide based on slide click block card slider
 */

$(".block-card-slider .slick-slide").click(function (e) {
  if (e.target.localName !== "a") {
    e.preventDefault();
    let slideNo = $(this).attr("data-slick-index");
    $(".block-card-slider").slick("slickGoTo", slideNo);
  }
});

$(".accordion-list > li > .answer").hide();

$(".accordion-list > li").click(function () {
  if ($(this).hasClass("active")) {
    $(this).removeClass("active").find(".answer").slideUp();
  } else {
    $(".accordion-list > li.active .answer").slideUp();
    $(".accordion-list > li.active").removeClass("active");
    $(this).addClass("active").find(".answer").slideDown();
  }
  return false;
});

//header tab
function openHeaderTab(evt, tabName) {
  var i, headertabcontent, headertablinks;
  headertabcontent = document.getElementsByClassName("headertabcontent");
  for (i = 0; i < headertabcontent.length; i++) {
    headertabcontent[i].style.display = "none";
  }
  headertablinks = document.getElementsByClassName("headertablinks");
  for (i = 0; i < headertablinks.length; i++) {
    headertablinks[i].className = headertablinks[i].className.replace(
      " active",
      ""
    );
  }
  $("#" + tabName).fadeIn(1500);
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
}

function openTab(evt, tabName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active";
}

function openTabSalesForce(evt, tabName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  $("#" + tabName).fadeIn(1500);
  evt.currentTarget.className += " active";
  if (window.innerWidth < 600) {
    $(".tab-sales-title-wrapper").find(".tab-content").html("");
    evt.currentTarget
      .closest(".tab-wrapper")
      .querySelector(".tab-content").innerHTML = document.querySelector(
        "#" + tabName
      ).innerHTML;
  }
  //extra add test
  if (window.innerWidth > 600 && window.innerWidth <= 770) {
    $(".tab-sales-title-wrapper").find(".tab-content").html("");
    evt.currentTarget
      .closest(".tab-wrapper")
      .querySelector(".tab-content").innerHTML = document.querySelector(
        "#" + tabName
      ).innerHTML;
  }
}

// if($('.tab-sales-title-wrapper')){
//     if(window.innerWidth < 600){
//         $('.tab-sales-title-wrapper').find('.tab-content').html('');
//         $('.tab-sales-title-wrapper .active').closest('.tab-wrapper').find('.tab-content').html( $('#servTab1').html());
//     }
//     if(window.innerWidth > 600 && window.innerWidth <= 770){
//         $('.tab-sales-title-wrapper').find('.tab-content').html('');
//         $('.tab-sales-title-wrapper .active').closest('.tab-wrapper').find('.tab-content').html( $('#servTab1').html());
//     }

// }

const tabSalesTitleWrapper = $(".tab-sales-title-wrapper");
const windowWidth = window.innerWidth;

if (tabSalesTitleWrapper.length) {
  let tabContent = "";

  if (windowWidth <= 770 && windowWidth >= 600) {
    tabContent = $("#servTab1").html();
  } else if (windowWidth < 600) {
    tabContent = $("#servTab1").html();
  }

  tabSalesTitleWrapper.find(".tab-content").html("");
  tabSalesTitleWrapper
    .find(".active")
    .closest(".tab-wrapper")
    .find(".tab-content")
    .html(tabContent);
}

$(".block-card-slider").slick({
  centerMode: true,
  // centerPadding: '6%',
  slidesToShow: 3,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block"),
  nextArrow: $(".next-block"),
  responsive: [
    {
      breakpoint: 950,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
});

$(".digital-card-slider").slick({
  centerMode: true,
  // centerPadding: '6%',
  slidesToShow: 3,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block-1"),
  nextArrow: $(".next-block-1"),
  responsive: [
    {
      breakpoint: 950,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "100px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
});

if ($(".testimonial-card-slider")) {
  let originalTestimonialCount = $(".testimonial-card-slider .slide").length;

  $(".testimonial-card-slider").slick({
    centerMode: true,
    centerPadding: "20px",
    slidesToShow: 3,
    arrows: true,
    swipe: true,
    autoplay: true,
    swipeToSlide: true,
    speed: 1000,
    // fade: true,
    prevArrow: $(".testimonial-card .prev-test"),
    nextArrow: $(".testimonial-card .next-test"),
    responsive: [
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "100px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
    ],
  });
  $(".testimonial-card-slider").on(
    "beforeChange",
    function (e, slick, currentSlide, nextSlide) {
      const slideCount = originalTestimonialCount - 1;
      const nextSlideIsFirst = currentSlide === slideCount;
      const nextSlideIsLast = nextSlide === slideCount;
      const $activeSlide = $(
        ".testimonial-card-slider .slick-slide.slick-current"
      );
      if (nextSlideIsFirst) {
        $activeSlide.next(".slick-slide").addClass("slick-clone-current");
        $activeSlide
          .next(".slick-slide")
          .next(".slick-slide")
          .addClass("slick-clone-current-next");
        $activeSlide
          .next(".slick-slide")
          .prev(".slick-slide")
          .addClass("slick-clone-current-prev");
      }
    }
  );

  // Remove your custom active class active slide finished
  $(".testimonial-card-slider").on(
    "afterChange",
    function (event, slick, currentSlide) {
      $(".slick-clone-current").removeClass("slick-clone-current");
      $(".slick-clone-current-next").removeClass("slick-clone-current-next");
      $(".slick-clone-current-prev").removeClass("slick-clone-current-prev");
    }
  );
}

$(".google-review-slider").slick({
  centerMode: true,
  centerPadding: "60px",
  slidesToShow: 3,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-review"),
  nextArrow: $(".next-review"),
  responsive: [
    {
      breakpoint: 950,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "100px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
});

$(".aso-card-slider").slick({
  centerMode: true,
  // centerPadding: '6%',
  slidesToShow: 1,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block-1"),
  nextArrow: $(".next-block-1"),
  responsive: [
    {
      breakpoint: 950,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "100px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
  ],
});

$(".aso-cusotm-card-slider").slick({
  centerMode: true,
  // centerPadding: '6%',
  slidesToShow: 3,
  arrows: false,
  dots: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block-1"),
  nextArrow: $(".next-block-1"),
  responsive: [
    {
      breakpoint: 950,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "100px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "35px",
        slidesToShow: 1,
      },
    },
  ],
});

$(".about-journey-slider").slick({
  centerMode: true,
  // centerPadding: '6%',
  slidesToShow: 3,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block-1"),
  nextArrow: $(".next-block-1"),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
});

$(".project-card-slider").slick({
  centerMode: true,
  centerPadding: "8%",
  slidesToShow: 1,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block"),
  nextArrow: $(".next-block"),
  responsive: [
    {
      breakpoint: 900,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "18px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "25px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "10px",
        slidesToShow: 1,
      },
    },
  ],
});


$(".au-project-card-slider").slick({
  centerMode: true,
  centerPadding: "8%",
  slidesToShow: 1,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  // fade: true,
  prevArrow: $(".prev-block"),
  nextArrow: $(".next-block"),
  responsive: [
    {
      breakpoint: 900,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "18px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "25px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "10px",
        slidesToShow: 1,
      },
    },
  ],
});


if ($("#mob_ab_gal").length > 0) {
  $("#mob_ab_gal").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 5,
    nav: false,
    autoplaySpeed: 6000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
    },
  });
}

//   var obj = {
//     'project': [{
//             "Category": "Health",

//         }, {
//           "Category": "Business",
//            },
//            {
//             "Category": "E-commerce",
//            },
//         ]
// };

// var newArray = obj.project.filter(function (el)
// {
//   return el.Category == "Business";
// }
// );
// console.log(newArray);

// $("select#categories").on('change', function() {
//   const selectedCategory = $(this).val();
//   $(`#all-projects .project-item`).show()
//   if (selectedCategory) {
//     $(`#all-projects .project-item[data-category!="${selectedCategory}"]`).hide()
//   }
//   if(AOS){
//       AOS.refresh();
//     }
// })

// $("select#types").on('change', function() {

//   const selectedType = $(this).val();
//   $(`#all-projects .project-item`).show()
//   if (selectedType) {
//     $(`#all-projects .project-item[data-type!="${selectedType}"]`).hide()
//   }
//   if(AOS){
//       AOS.refresh();
//     }
//   console.log("hello");
// })

$(".platform-type").on("click", function () {
  // $('.dropdown-port-p').find('span').text('Category');
  // $('.dropdown-port-p').find('input').attr('value', '');
  $(".type-list").find(".active").removeClass("active");
  $(this).find("img").addClass("active");
  const type = $(this).attr("data-type");
  $("select#categories").val("");
  $(`#all-projects .project-item`).show();
  $(`.project-item[data-${type}!="true"]`).hide();
  const selectedCategoryType = $(".dropdown-port-p")
    .find("input")
    .attr("value");
  $(
    `#all-projects .project-item[data-category="${selectedCategoryType}"]`
  ).show();
  if (AOS) {
    AOS.refresh();
  }
});

$(".dropdown-port-p").click(function () {
  $(this).attr("tabindex", 1).focus();
  $(this).toggleClass("active");
  $(this).find(".dropdown-menu-port-p").slideToggle(300);
});
$(".dropdown-port-p").focusout(function () {
  $(this).removeClass("active");
  $(this).find(".dropdown-menu-port-p").slideUp(300);
});
$(".dropdown-port-p .dropdown-menu-port-p li").click(function () {
  $(this).parents(".dropdown-port-p").find("span").text($(this).text());
  $(this)
    .parents(".dropdown-port-p")
    .find("input")
    .attr("value", $(this).attr("data-category"));

  //$('.type-list').find('.active').removeClass('active');
  const selectedCategory = $(this).data("category");
  $(`#all-projects .project-item`).show();
  if (selectedCategory) {
    $(
      `#all-projects .project-item[data-category!="${selectedCategory}"]`
    ).hide();
  }

  let type = $(".type-list")
    .find(".active")
    .closest(".platform-type")
    .data("type");

  $(`#all-projects .project-item[data-${type}="true"]`).show();

  if (AOS) {
    AOS.refresh();
  }
});
/*End Dropdown Menu*/

// $('.dropdown-menu-port-p li').click(function () {
//   var input = '<strong>' + $(this).parents('.dropdown-port-p').find('input').val() + '</strong>',
//       msg = '<span class="msg">Hidden input value: ';
//   $('.msg').html(msg + input + '</span>');
// });

//ASO page script
$(".custom-card-list-item").click(function () {
  $(this)
    .closest(".custom-card-list-wrap")
    .find(".active")
    .removeClass("active");
  $(this).addClass("active");
});

//$(document).on('ready', function() {
$(".vertical-slider-slides").slick({
  // centerMode: true,
  vertical: true,
  // centerPadding: '60px',
  speed: 2000,
  infinite: true,
  slidesToShow: 1.5,
  autoplay: true,
  arrows: false,
  //autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        vertical: false,
        slidesToShow: 1,
        arrows: false,
        centerMode: true,
        //centerPadding: '40px',
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        vertical: false,
        arrows: false,
        centerMode: true,
        // centerPadding: '10px',
        slidesToShow: 1,
      },
    },
  ],
});
$(".slider").on(
  "beforeChange",
  function (event, slick, currentSlide, nextSlide) {
    console.log("$(event.target)", event);
    console.log(
      "change",
      nextSlide,
      "currentSlide",
      currentSlide,
      "event",
      $(event.target).find("[data-title]").data("title")
    );
    let title = $(".slider .slide-" + nextSlide).data("title");
    console.log("title", title);
    //.find('[data-title]').data('title');

    $(".slide-img").removeClass("floating-content");
    setTimeout(function () {
      $(".slide-img").addClass("floating-content");
    }, 1000);

    $("#content-data-" + currentSlide).removeClass("show-animation");
    setTimeout(function () {
      $("#content-data-" + currentSlide).addClass("show-animation-clear");
    }, 1);
    $(".portfolio-section-1-c").css(
      "background-color",
      $("#content-data-" + nextSlide).data("color")
    );
    $("#content-data-" + nextSlide).addClass("show-animation");
    $("#content-data-" + nextSlide).removeClass("show-animation-clear");
    // $('#content-data').html(title);
    setTimeout(function () { }, 700);
  }
);

// });

// sub+service_script--end
// service_new_m->start  -

// var service_tab_nav = document.getElementById("service_tab_nav");
// if (service_tab_nav) {
//     window.onscroll = function () { myFunction() };
//     var sticky = service_tab_nav.offsetTop;

//     function myFunction() {
//         if (window.pageYOffset > sticky) {
//             service_tab_nav.classList.add("sticky");
//         } else {
//             service_tab_nav.classList.remove("sticky");
//         }
//     }
// }

var pageHeight = $("html, body").height();
var srv_head = $(".srv_head").height();
var sectionHt = $(".serv_all").height();
$(window).scroll(function () {
  if ($(this).scrollTop() > srv_head + sectionHt + 250) {
    $("#service_tab_nav").addClass("small");
  } else {
    $("#service_tab_nav").removeClass("small");
  }
});

$(document).ready(function () {
  if (window && window.location.pathname === "/services") {
    setTimeout(function () {
      $("body").append(
        "<div class='modal-overlay js-modal-close' style='opacity: 0.7;'></div>"
      );
      $("body").addClass("popup_open");
      $("#getin_touch_popup").css("display", "block");
    }, 15000);
  }
});

// service_new_m-> end
// aboit_new_m-> start

$("document").ready(function () {
  setTimeout(() => {
    $("#rightHover").mouseenter();
    runSlide = true;
  }, 100);
  let boxWidth = $(".manualSlideBox").innerWidth();
  let boxOffsetWidth = $(".manualSlideBox").innerWidth() - $(window).width();
  let startPoint = $(window).width();
  let translate = `translateX(-${startPoint}px)`;
  console.log("translate", translate);
  $(".manualSlideBox").css("transform", "translateX(0px)");
  //alert('hello')
  let interval = null;
  var runSlide = true;

  $(".leftHover").mouseout(function () {
    runSlide = true;
  });

  $(".leftHover").mouseenter(function () {
    //alert('hello');
    runSlide = false;
    //     $(".manualSlideBox").addClass("leftRun").removeClass('rightRun');
    //     let ofvalue = $(".manualSlideBox").offset().left;
    //     let mnBx = -ofvalue;
    //     clearInterval(interval);
    //    interval = setInterval(function(){
    //     $(".manualSlideBox.leftRun").css("transform", `translateX(-${mnBx}px)`);
    //     if(runSlide) {
    //         mnBx++;
    //     }
    //     //console.log('mnBx', mnBx, 'boxOffsetWidth', boxOffsetWidth)
    //         if(mnBx == boxOffsetWidth){
    //             mnBx = 80
    //         }
    //     }, 0.01);
  });

  $(".rightHover").mouseout(function () {
    runSlide = true;
  });

  $(".rightHover").mouseenter(function () {
    //alert('hello');
    runSlide = false;
    $(".manualSlideBox").addClass("rightRun").removeClass("leftRun");
    let ofvalue = $(".manualSlideBox").offset().left;
    let mnBxRt = ofvalue;
    clearInterval(interval);
    interval = setInterval(function () {
      $(".manualSlideBox.rightRun").css("transform", `translateX(${mnBxRt}px)`);
      if (runSlide) {
        mnBxRt++;
      }
      //console.log('mnBx', mnBxRt, 'boxOffsetWidth', boxOffsetWidth)
      if (mnBxRt == 40) {
        mnBxRt = -boxOffsetWidth - 40;
      }
    }, 10);
  });
});

if ($("#mob_ab_gal").length > 0) {
  $("#mob_ab_gal").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 5,
    nav: false,
    autoplaySpeed: 6000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
      },
    },
  });
}

// aboit_new_m-> end
// wr_d->start

$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(window).scroll(function () {
  $(".rgt-rnd-ln").each(function (i, el) {
    if ($(this).isInViewport()) {
      setInterval(function () {
        $(".srb_bx_one").fadeIn(800);
      }, 1);
      setInterval(function () {
        $(".srb_bx_one img").css("display", "block");
      }, 100);

      setInterval(function () {
        $(".srb_bx_two").fadeIn(800);
      }, 1200);
      setInterval(function () {
        $(".srb_bx_two img").css("display", "block");
      }, 1500);

      setInterval(function () {
        $(".srb_bx_three").fadeIn(800);
      }, 2600);
      setInterval(function () {
        $(".srb_bx_three img").css("display", "block");
      }, 2900);

      setInterval(function () {
        $(".srb_bx_four").fadeIn(800);
      }, 4200);
      setInterval(function () {
        $(".srb_bx_four img").css("display", "block");
      }, 4500);

      setInterval(function () {
        $(".srb_bx_five").fadeIn(800);
      }, 5500);
      setInterval(function () {
        $(".srb_bx_five img").css("display", "block");
      }, 5800);

      setInterval(function () {
        $(".srb_bx_six").fadeIn(800);
      }, 6800);
    }
  });
});

// wr_d->end
// career_d -> start

$(document).ready(function () {
  $(
    "#accordionExample .accordion-item:first-child .accordion-button"
  ).removeClass("collapsed");
});

// career_d -> end
// career_m -> start

$(document).ready(function () {
  $("#openMoreFt").click(function () {
    //alert("fasjdfasklj");
    $(this).hide();
    $("#moreFt").show();
  });
});

$(document).ready(function () {
  $(
    "#accordionExampleCareer .accordion-item:first-child .accordion-button"
  ).removeClass("collapsed");
});

$("document").ready(function () {
  let boxWidth = $(".manualSlideBox").innerWidth();
  let boxOffsetWidth = $(".manualSlideBox").innerWidth() - $(window).width();
  let startPoint = $(window).width();
  let translate = `translateX(-${startPoint}px)`;
  console.log("translate", translate);
  $(".manualSlideBox").css("transform", "translateX(0px)");
  //alert('hello')
  let interval = null;

  $(".leftHover").mouseout(function () {
    $(".manualSlideBox").removeClass("leftRun");
    clearInterval(interval);
  });

  //    $(".leftHover").mouseover(function(){
  //         $(".manualSlideBox").addClass("leftRun");
  //         let ofvalue = $(".manualSlideBox").offset().left;
  //         let mnBx = -ofvalue;

  //        interval = setInterval(function(){
  //         $(".manualSlideBox.leftRun").css("transform", `translateX(-${mnBx}px)`);
  //         mnBx++;
  //             if(mnBx == boxOffsetWidth){
  //                 mnBx = 80
  //             }
  //         }, 0.01);
  //    });
});

if ($("#mob_ab_gal").length > 0) {
  $("#mob_ab_gal").owlCarousel({
    loop: true,
    autoplay: true,
    margin: 5,
    nav: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
      },
    },
  });
}

if ($("#intern-slider")) {
  $("#intern-slider").owlCarousel({
    loop: true,
    stagePadding: 30,
    autoplay: true,
    margin: 38,
    nav: false,
    responsiveClass: true,
    speed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });
}

if ($("#intern-slider-mob")) {
  $("#intern-slider-mob").owlCarousel({
    loop: true,
    stagePadding: 30,
    autoplay: true,
    slideBy: 3,
    margin: 38,
    nav: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });
}

// career_m -> end

//top scroll btn

var btn = $("#scroll-top-button");

$(window).scroll(function () {
  if ($(window).scrollTop() > 300) {
    btn.addClass("show");
  } else {
    btn.removeClass("show");
  }
});

btn.on("click", function (e) {
  e.preventDefault();
  $("html, body").animate({ scrollTop: 0 }, "300");
});

// $(document).ready(function () {
//   $(".slider-hm-content").slick({
//     vertical: true,
//     autoplay: true,
//     autoplaySpeed: 4000,
//     speed: 400,
//     arrows: false,
//   });
//   $(".slider-container").css("display", "block");
// });

$(".slider-career-content").slick({
  vertical: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 300,
  arrows: false,
});

$(".custom-software-development-wrapper .sr-sub-head").click(function () {
  let hasClass = false;
  if ($(this).closest(".sr-box").hasClass("active")) {
    hasClass = true;
  }
  console.log("hasClass", hasClass);
  $(".custom-software-development-wrapper")
    .find(".active")
    .removeClass("active");
  if (!hasClass) {
    $(this).closest(".sr-box").addClass("active");
  }
});

// $(window).ready(function () {
//     if(window.screen.width < 768 && $('.switch-id-enquiry')){
//         $('.switch-id-enquiry').attr('data-bs-target', '#project_enquery_mob');
//     }
// })

$(window).ready(function () {
  if (window.screen.width < 990 && $(".switch-id-enquiry")) {
    $(".switch-id-enquiry").attr("data-bs-target", "#project_enquery_mob");
  }
});



// mobile app development page gif


document.addEventListener('DOMContentLoaded', function () {
  const videoContainer = document.querySelector('.project-vdo');
  if (!videoContainer) {
    return
  }
  const video = videoContainer.querySelector('video');
  const coverImage = videoContainer.querySelector('.cover-image');

  videoContainer.addEventListener('mouseover', function () {
    coverImage.style.display = 'none';
    video.play();
  });

  videoContainer.addEventListener('mouseout', function () {
    video.pause();
    coverImage.style.display = 'block';
  });
});



// faq expand for mobile app development page

document.addEventListener("DOMContentLoaded", function () {
  const toggleFaqBtn = document.getElementById('toggle-faq-btn');
  if (!toggleFaqBtn) {
    return
  }
  const faqItems = document.querySelectorAll('.accordion-list li');
  const leftFaqItems = document.querySelectorAll('.col-md-6:first-child .accordion-list li');
  const rightFaqItems = document.querySelectorAll('.col-md-6:last-child .accordion-list li');
  const initialLeftVisibleCount = 4;
  const initialRightVisibleCount = 3;
  let showingAll = false;

  // Show initial set of FAQs
  function showInitialFaqs() {
    for (let i = 0; i < leftFaqItems.length; i++) {
      leftFaqItems[i].classList.toggle('visible', i < initialLeftVisibleCount);
    }
    for (let i = 0; i < rightFaqItems.length; i++) {
      rightFaqItems[i].classList.toggle('visible', i < initialRightVisibleCount);
    }
    if (!toggleFaqBtn) {
      return
    }
    toggleFaqBtn.textContent = "View more FAQ’s";
  }

  // Show all FAQs
  function showAllFaqs() {
    faqItems.forEach(faq => faq.classList.add('visible'));
    toggleFaqBtn.textContent = "View less FAQ’s";
  }

  // Initial display
  showInitialFaqs();

  // Toggle FAQs on button click
  toggleFaqBtn.addEventListener('click', function () {
    if (showingAll) {
      showInitialFaqs();
    } else {
      showAllFaqs();
    }
    showingAll = !showingAll;
  });
});



// process section for mobile app development page

// document.addEventListener('DOMContentLoaded', function () {
//     const stepsContainer = document.querySelectorAll('.steps-container .step');
//     const contents = document.querySelectorAll('.content-box .content');
//     const mobileSteps = document.querySelectorAll('.accordion-list .mob-design');

//     // Function to activate a step and corresponding content
//     function activateStep(stepId) {
//         // Deactivate all dots and contents
//         stepsContainer.forEach(s => {
//             s.querySelector('.dot').classList.remove('active');
//             s.querySelector('.plan-btn').classList.remove('active');
//         });
//         contents.forEach(content => content.classList.remove('active'));

//         // Activate the clicked dot and corresponding content
//         const activeStep = document.querySelector(`.steps-container .step[data-step="${stepId}"]`);
//         activeStep.querySelector('.dot').classList.add('active');
//         activeStep.querySelector('.plan-btn').classList.add('active');
//         const activeContent = document.querySelector(`.content-box .content[data-content="${stepId}"]`);
//         activeContent.classList.add('active');
//     }

//     stepsContainer.forEach(step => {
//         step.addEventListener('click', function () {
//             const stepId = this.getAttribute('data-step');
//             activateStep(stepId);
//         });
//     });

//     mobileSteps.forEach(mobileStep => {
//         mobileStep.addEventListener('click', function () {
//             const stepId = this.querySelector('.faq-content .question img').getAttribute('alt').replace('Project Scoping', 'A1').replace('Designs, Wireframes & Prototypes', 'A2').replace('Estimates & Timeline', 'A3');

//             // Activate the clicked mob-design and corresponding step
//             mobileSteps.forEach(m => {
//                 m.classList.remove('active');
//                 m.querySelector('.faq-content').classList.remove('active');
//             });
//             this.classList.add('active');
//             this.querySelector('.faq-content').classList.add('active');

//             // Activate the corresponding step and its dot
//             activateStep(stepId);

//             // Highlight the dot in the desktop view
//             const correspondingStep = document.querySelector(`.steps-container .step[data-step="${stepId}"]`);
//             correspondingStep.querySelector('.dot').classList.add('active');
//         });
//     });

//     // Activate the first step of Phase A by default
//     document.querySelector('#phaseA .step[data-step="A1"]').click();
// });





document.addEventListener('DOMContentLoaded', function () {
  const stepsContainer = document.querySelectorAll('.steps-container .step');
  const contents = document.querySelectorAll('.content-box .content');
  const mobileSteps = document.querySelectorAll('.accordion-list .mob-design');

  // Function to activate a step and corresponding content
  function activateStep(stepId) {
    // Deactivate all dots and contents
    stepsContainer.forEach(s => {
      s.querySelector('.dot').classList.remove('active');
      s.querySelector('.plan-btn').classList.remove('active');
    });
    contents.forEach(content => content.classList.remove('active'));

    // Activate the clicked dot and corresponding content
    const activeStep = document.querySelector(`.steps-container .step[data-step="${stepId}"]`);
    activeStep.querySelector('.dot').classList.add('active');
    activeStep.querySelector('.plan-btn').classList.add('active');
    const activeContent = document.querySelector(`.content-box .content[data-content="${stepId}"]`);
    activeContent.classList.add('active');
  }

  // Event listener for desktop steps
  stepsContainer.forEach(step => {
    step.addEventListener('click', function () {
      const stepId = this.getAttribute('data-step');
      activateStep(stepId);
    });
  });

  // Event listener for mobile accordion items
  mobileSteps.forEach(mobileStep => {
    mobileStep.addEventListener('click', function () {
      const stepId = this.closest('.step').getAttribute('data-step');

      // Activate the clicked mob-design and corresponding step
      mobileSteps.forEach(m => {
        m.classList.remove('active');
        m.querySelector('.faq-content').classList.remove('active');
      });
      this.classList.add('active');
      this.querySelector('.faq-content').classList.add('active');

      // Activate the corresponding step and its dot
      activateStep(stepId);
    });
  });



  // Activate the first step of Phase A by default

  const stepElement = document.querySelector('#phaseA .step[data-step="A1"]');

  if (stepElement) {
    stepElement.click();
  } else {
    console.log('Element not found');
  }
});



